import { graphql, useStaticQuery } from "gatsby"

import { QueryFragments } from "./queryFragments" // eslint-disable-line

export const useSiteConfig = () => {
  const { sanitySiteConfig } = useStaticQuery(graphql`
    query {
      sanitySiteConfig {
        projectPageTitle
        clienttPageTitle
        mainNav {
          ... on SanityPageLink {
            _type
            _key
            ...PageLinkFields
          }
          ... on SanityPathLink {
            _type
            ...PathLinkFields
          }
        }
        footerTitle
        footerNav {
          ... on SanityPageLink {
            _type
            _key
            ...PageLinkFields
          }
          ... on SanityPathLink {
            _type
            ...PathLinkFields
          }
        }
        _rawText(resolveReferences: { maxDepth: 10 })
      }
    }
  `)
  return sanitySiteConfig || {}
}
