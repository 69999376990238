import React from "react"

import { PageLink, ExternalLink } from "../ui"

export const NavLink = ({ _type, ...rest }) => {
  const LinkComponent = () => {
    switch (_type) {
      case "pageLink":
        return <PageLink {...rest} />
      case "pathLink":
        return <ExternalLink {...rest} />
      default:
        return <h1>null</h1>
    }
  }
  return (<LinkComponent />)
}
