import React from "react"
import AnchorScroll from "react-anchor-link-smooth-scroll"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { Link } from "gatsby"

// Hooks & Querys
import { useSanityPath } from "../../hooks"

export const PageLink = ({ page, linkText, ...rest }) => {
  const path = useSanityPath(page)
  return (
    <Link to={path} {...rest}>
      {linkText}
    </Link>
  )
}

export const PathLink = ({ path, linkText, ...rest }) => {
  return (
    <Link to={`/${path}`} {...rest}>
      {linkText}
    </Link>
  )
}

export const PageAnchorLink = ({ anchor, page, linkText, ...rest }) => {
  const path = useSanityPath(page)
  return (
    <AnchorLink to={`/${path}#${anchor}`} {...rest}>
      {linkText}
    </AnchorLink>
  )
}

export const AnchorScrollLink = ({ anchor, linkText, ...rest }) => {
  return (
    <AnchorScroll to={`#${anchor}`} {...rest}>
      {linkText}
    </AnchorScroll>
  )
}

export const ExternalLink = ({ linkText, path, link, ...rest }) => {

  if(isValidUrl(link)){
    return (
      <a href={link} target="_blank" rel="noreferrer">
        {linkText}
      </a>
    )
  }else{
    return(
      <Link to={path} {...rest}>
      {linkText}
    </Link>
    )
  }
  

}



function isValidUrl(string) {
  try {
    new URL(string);
    return true;
  } catch (err) {
    return false;
  }
}