export const usePadTop = size => {
  switch (size) {
    case "small":
      return "pb-twf md:pt-small"
    case "medium":
      return "pt-32 md:pt-medium"
    case "large":
      return "pt-6 md:pt-large"
    case "extraLarge":
        return "pt-12 md:pt-extra-large"
    default:
     return "pt-0"
  }
}
