import React, { useEffect, useState } from 'react'
import { StaticQuery, graphql, Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image'
import { motion, AnimatePresence } from "framer-motion";
import { fadeOut } from '../lib/animations'
import { useClientQuery } from '../GraphQl/useClientQuery'
import { useSiteConfig } from "../GraphQl/useSiteConfig"


const ClientsAll = ({ handleClose }) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const { edges } = useClientQuery()
    const { clienttPageTitle } = useSiteConfig()

    const [clients, setClients] = useState([]);

    const handleMouse = _key => {
        setActiveIndex(_key)
    }

    useEffect( () => {
        const _clients = edges.sort((a,b) => a.node.title.toLowerCase() < b.node.title.toLowerCase() ? -1 : 1)
        setClients(_clients)
    },[edges])

    return (
        <motion.div initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        variants={fadeOut}
        className="client--modal">
            <div className="section h-full" id="clientWrapper">
                <div className="col-span-6 h-full">
                    <div id="all-clients-list">
                        <div className="case-list--inner">
                            <div className="case-list--back">
                                <button className="close--btn mb-16" onClick={handleClose}>
                                    Back
                                </button>
                            </div>

            <div className="case-list--items">
                <h2 className="mb-5 text-p-heading font-heading">{clienttPageTitle}</h2>
                <ul>
                {clients.map(client => {
                    
                    const { _id, slug, title, postImage, alt, caseStudy } = client.node

                    return (

                    <li key={_id} 
                        onMouseEnter={() => handleMouse(_id)}
                        className=" flex mb-list">
                        <Link className={`w-full pointer-events-none`}
                            to={`/clients/${slug.current}`}
                            disabled={!caseStudy}>

                                {title && (
                                    <span className={`font-body text-p-main transition duration-300 ease-in-out ${activeIndex === client.node._id ? "opacity-50" : "opacity-100" }`}>
                                        {title}
                                    </span>
                                )}
                                <AnimatePresence
                                    initial={false}
                                    exitBeforeEnter={true}
                                    onExitComplete={() => null}>
                                        {activeIndex === _id && (
                                            <motion.div key={_id}
                                                className="clientImage absolute top-0 right-0 left-1/2 hidden md:block"
                                                initial={{ opacity: 0 }}
                                                animate={{ opacity: 1 }}
                                                exit={{ opacity: 0 }}
                                                transition={{ duration: 0.3 }}>
                                                    {postImage && (
                                                        <GatsbyImage className="hidden md:block" 
                                                        objectFit="cover" objectPosition="center" 
                                                        alt={alt || 'Someday Studio Client Portfolio Image'} 
                                                        image={postImage.asset.gatsbyImageData} />
                                                    )}
                                            </motion.div>                                
                                        )}
                                </AnimatePresence>
                            </Link>
                        </li>
                    )}
                )}
                </ul>
                </div>
            </div>
        </div>
    </div>
</div>
        </motion.div>
    )
}

export default ClientsAll
