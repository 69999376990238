import React from "react"

import { AnimatePresence, motion } from "framer-motion"

import "../css/main.css"
import "../css/typography.css"

import { useLocation } from "@reach/router"

import { Header } from "../components/nav/header"
import { Footer } from "../components/nav/footer"



const Layout = ({ children }) => {

  const variants = {
    hidden: { opacity: 0, x: 0, y: 0 },
    enter: { opacity: 1, x: 0, y: 0 },
    exit: { opacity: 0, x: 0, y: 0 },
  }


  const { pathname } = useLocation()
  return (
    <>
      <Header />
      <AnimatePresence>
        <motion.main
          className=""
          key={pathname}
          variants={variants}
          initial="hidden"
          animate="enter"
          exit="exit"
          transition={{ type: 'linear' }} 
        >
          {children}
        </motion.main>
      </AnimatePresence>
      <Footer />
    </>
  )
}

export default Layout
