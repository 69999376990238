export const usePadBottom = size => {
  switch (size) {
    case "small":
      return "pb-twf md:pb-small"
    case "medium":
      return "pb-small md:pb-medium"
    case "large":
      return "pb-6 md:pb-large"
    case "extraLarge":
      return "pb-12 md:pb-extra-large"
    default:
      return "pb-0"
  }
}
