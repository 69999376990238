import React from "react"
import PortableText from "react-portable-text"

// Block Components
import {
  AnchorScrollLink,
  ExternalLink,
  PageAnchorLink,
  PageLink,
  PathLink,
} from "../ui/links"
import { ImageModule, VideoModule } from "../modules"

const serializers = {
  h1: props => <h1 className="font-heading" {...props} />,
  h2: props => <h2 className="font-heading" {...props} />,
  h3: props => <h3 className="font-heading" {...props} />,
  h4: props => <h4 className="font-heading" {...props} />,
  h5: props => <h5 className="font-heading" {...props} />,
  h6: props => <h6 className="font-heading" {...props} />,
  "super-script": props => <sup>{props.children}</sup>,
  "sub-script": props => <sub>{props.children}</sub>,
  pageLink: props => <PageLink {...props} className="underline" />,
  pathLink: props => <PathLink {...props} className="underline" />,
  anchorLink: props => <AnchorScrollLink {...props} className="underline" />,
  externalLink: props => <ExternalLink {...props} className="underline" />,
  pageAnchorLink: props => <PageAnchorLink {...props} className="underline" />,
  normal: props => <p {...props} />,
  strong: (props) => <strong {...props} className="" />,
  imageModule: props => <ImageModule {...props} />,
  videoModule: props => <VideoModule {...props} />,
  undefined: props => <p {...props} />
} // eslint-disable-line

export const PortableTextBlock = ({ text, ...props }) => {
  return <PortableText content={text} serializers={serializers} {...props} />
}
