export const blockWidth = width => {
    switch (width) {
      case "fullWidth":
        return "col-span-6 md: col-span-12"
      case "indent":
        return "col-span-6 md:col-start-2 md:col-end-12"
      case "half":
        return "col-span-6 md:col-start-7 md:col-end-12"
      case "threeQuarters":
        return "col-span-6 md:col-start-8 md:col-end-12"
      default: 
        return "col-span-6"
    }
  }
  