import React, {useEffect, useState} from "react"
import ReactPlayer from "react-player"


export const VideoModule = ({ asset, cover=false }) => {

  const [muted, setMuted] = useState(true);
  const [expanded, setExpanded] = useState(false);

  if(cover){
    return(
      <div onClick={() => {setExpanded(!expanded); setMuted(!muted)}} className={`video--wrapper-cover ${expanded ? '!w-screen video--wrapper-open' : ''}`}>
        <ReactPlayer url={asset.url} playing={true} muted={muted} loop />
        <button onClick={()=>{setMuted(!muted)}} className="save-button uppercase text-p-body unmute-button">CLICK TO {muted ? 'UNMUTE' : 'MUTE'}</button>
    </div>
    )
  }else{
    return (
      <div className="video--wrapper">
          <ReactPlayer url={asset.url} playing={true} muted={muted} loop />
          <button onClick={()=>setMuted(!muted)} className="save-button uppercase text-p-body unmute-button">CLICK TO {muted ? 'UNMUTE' : 'MUTE'}</button>
      </div>
    )
  }

  
}
