export const textBlockStyle = style => {
    switch (style) {
      case "headingHero":
        return "prose headings--rte textBlock_headingHero"
      case "headingHeroXl":
        return "prose headings--rte textBlock_headingHeroXl"
      case "bodyCopy":
        return "prose body--rte textBlock_bodyCopy"
      case "bodyCopyLarge":
        return "prose-body--rte-lg textBlock_bodyCopyLarge"
      case "bodyCopySmall":
        return "prose md:prose-body--rte-sm textBlock_bodyCopySmall"
      default :
        return "prose"
    }
  }
  