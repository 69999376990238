import { useStaticQuery, graphql } from 'gatsby';

export const useClientQuery = () => {
  const { allSanityClientPost } = useStaticQuery(graphql`
    query {
      allSanityClientPost {
          edges {
            node {
                caseStudy
                _id
                _key
                postImage {
                _rawAsset(resolveReferences: {maxDepth: 10})
                _key
                _type
                alt
              asset {
                gatsbyImageData(fit: FILLMAX, placeholder: DOMINANT_COLOR)
              }
              hotspot {
                  _key
                  _type
                  height
                  width
                  x
                  y
                }
              _rawCrop(resolveReferences: {maxDepth: 10})
              _rawHotspot
                }
              postExcerpt {
              _key
              style
              children {
                _key
                _type
                marks
                text
              }
              _rawChildren(resolveReferences: {maxDepth: 10})
              _type
            }
            slug {
              current
            }
            title
          }
        }
      }
    }
  `);
  return allSanityClientPost  || {};
};