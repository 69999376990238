import React, { useState, useEffect } from "react"
import { globalHistory } from '@reach/router'
import { useSiteConfig } from "../../GraphQl/useSiteConfig"
import { NavLink } from "./navLink"
import ThemeToggle from "../themeToggler"
import ClientsAll from '../clientsAll'
import { motion, AnimatePresence } from "framer-motion"
import ProjectsAll from '../projectsAll'


export const Header = () => {



  const { mainNav } = useSiteConfig()
  const [modalOpen, setModalOpen] = useState(false);
  const close = () => setModalOpen(false);
  const open = () => setModalOpen(true);

  const [projectOpen, setProjectOpen] = useState(false);
  const closeProject = () => setProjectOpen(false);
  const openProject = () => setProjectOpen(true);


  const [navbarOpen, setNavbarOpen] = useState(false)

  const handleToggle = () => {
    setNavbarOpen(prev => !prev)
  }
  

  useEffect(() => {
    return globalHistory.listen(({ action }) => {
      if (action === 'PUSH'){
        close() 
        closeProject()
        setNavbarOpen(false)
      }
    })
  })

  return (
    <header className="grid-default sticky-header">
      <AnimatePresence initial={false} exitBeforeEnter={true} onExitComplete={() => null}>
        {modalOpen && <ClientsAll modalOpen={modalOpen} handleClose={close} />}
      </AnimatePresence>

      <AnimatePresence initial={false} exitBeforeEnter={true} onExitComplete={() => null}>
      {projectOpen && <ProjectsAll projectOpen={projectOpen} handleCloseProject={closeProject} /> }
      </AnimatePresence>

      <nav className="hidden flex-spaced col-span-6 md:col-span-12 Class items-start pt-2 uppercase">
        
        
        {mainNav?.map(item => {
         switch (item.linkText) {
              case "Projects":
              return (
                <motion.button
                  key={23712220222022} 
                  className="save-button uppercase text-p-body"
                  onClick={() => (projectOpen ? closeProject() : openProject())}>
                    Projects
                </motion.button>
              )
              case "Clients":

                return (
                <motion.button key={23713490010101} className="text-p-body save-button uppercase" onClick={() => (modalOpen ? close() : open())}>
                  Clients
                </motion.button>
                )

              default:
                return <NavLink className="text-p-body" key={item._key} {...item} />
            }
          })}

        <div className="opacity-0	z-0"></div>
      </nav>

      <button id="mobMenuBtn" className="block md:hidden uppercase" onClick={handleToggle}>{navbarOpen ? "Close" : "Menu"}</button>
      
      <div key={237123561112} className="theme--toggle-mobile block">
        <ThemeToggle key={237123561112} />
      </div>

      <nav  className={`menuNav uppercase flex flex-col justify-between	md:hidden ${navbarOpen ? " showMenu" : ""}`}>
        <ul>
        {mainNav?.map(item => {
          switch (item.linkText) {
                case "Projects":
                return (
                <li key={item._key || 213432423466}>
                  <motion.button
                  className="save-button uppercase"
                  onClick={() => (projectOpen ? closeProject() : openProject())}>
                    Projects
                  </motion.button>
                </li>
                )
                case "Clients":

                  return (
                  <li key={item._key || 213432423431 }>
                  <motion.button className="save-button uppercase" onClick={() => (modalOpen ? close() : open())}>
                    Clients
                  </motion.button>
                  </li>
                  )

                default:
                  return <li key={item._key} ><NavLink {...item} /></li>
              }
            })}

          </ul>
          <div className="someday-mobile">
          <svg className="col-span-6 md:col-span-12" viewBox="0 0 1400 213" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M94.2007 129.237L69.2193 123.435C31.7472 115.522 7.28625 98.115 7.28625 63.8275C7.28625 22.6825 40.5948 0 81.71 0C129.071 0 156.134 28.485 159.777 68.0475H117.1C115.019 51.695 103.048 39.5625 81.71 39.5625C62.974 39.5625 50.4833 48.0025 50.4833 61.19C50.4833 70.685 57.2491 75.96 78.0669 80.7075L103.048 86.51C143.643 95.4775 164.981 112.357 164.981 147.7C164.981 186.735 134.275 212.582 85.8736 212.582C35.9108 212.055 4.16357 184.625 0 140.842H43.197C47.3606 162.47 62.4535 172.492 85.8736 172.492C108.773 172.492 121.784 163.525 121.784 149.81C121.264 139.26 114.498 133.985 94.2007 129.237Z" fill="var(--blue)"/>
                    <path d="M188.922 105.5C188.922 43.7825 235.242 0 293.532 0C351.822 0 399.182 44.31 399.182 105.5C399.182 167.745 351.822 211.527 293.532 211.527C235.242 212.055 188.922 167.745 188.922 105.5ZM233.16 105.5C233.16 140.842 256.059 170.382 294.572 170.382C332.565 170.382 355.465 140.842 355.465 105.5C355.465 70.685 331.004 40.6175 294.572 40.6175C256.059 41.145 233.16 70.685 233.16 105.5Z" fill="var(--blue)"/>
                    <path d="M511.599 167.217L473.086 92.3125V208.89H429.889V3.165H473.086L530.855 118.16L588.625 3.165H631.822V208.362H588.625V91.785L550.112 166.69H511.599V167.217Z" fill="var(--blue)"/>
                    <path d="M670.855 208.363V3.165H820.224V43.255H714.052V83.8725H815.539V123.435H714.052V168.272H820.224V208.363H670.855Z" fill="var(--blue)"/>
                    <path d="M858.216 208.363V3.165H924.833C979.48 3.165 1037.77 30.0675 1037.77 104.445C1037.77 179.35 979.48 208.363 924.833 208.363H858.216ZM918.588 43.255H901.413V168.272H918.588C961.785 168.272 994.573 151.92 994.573 104.445C994.573 58.5525 961.785 43.255 918.588 43.255Z" fill="var(--blue)"/>
                    <path d="M1162.68 3.165L1241.78 208.363H1197.03L1180.89 167.745H1102.31L1086.17 208.363H1041.41L1120.52 3.165H1162.68ZM1142.38 62.245L1117.4 129.238H1167.88L1142.38 62.245Z" fill="var(--blue)"/>
                    <path d="M1330.78 136.095V208.363H1287.58V136.095L1220.97 3.165H1267.81L1309.44 89.675L1351.6 3.165H1398.44L1330.78 136.095Z" fill="var(--blue)"/>
                </svg>
          </div>
      </nav>


    </header>
  )
}

