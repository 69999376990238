import React from "react"
import { ThemeToggler } from "gatsby-plugin-dark-mode"

export default function ThemeToggle() {
     return (
       <ThemeToggler>
        {({ theme, toggleTheme }) => (
           <div
            className="toggleButton rounded-full h-3.5	w-3.5 white-bg cursor-pointer"
            onClick={() => toggleTheme(theme === "dark" ? "light" : "dark")}
          > {' '}<span className="sr-only">Dark mode</span></div>     
         )}
        </ThemeToggler>
     )
}