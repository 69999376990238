import React, { useState, useEffect } from "react"


import { useSiteConfig } from "../../GraphQl/useSiteConfig"
import { NavLink } from "./navLink"
import { PortableTextBlock } from "../sanity/portableTextBlock"




export const Footer = () => {
  const { footerNav, footerTitle, _rawText } = useSiteConfig()


  
  return (
    <footer className="pb-twf md:pb-0">
      <div className="section pt-9 md:pb-twf footer--inner">
          <h2 className="font-heading uppercase col-span-6 md:col-span-3">{footerTitle}</h2>
          {_rawText && <address className="text-display col-span-6 md:col-start-10 md:col-end-12 uppercase"><PortableTextBlock text={_rawText} className="footer--adress not-italic col-span-6 md:col-start-10 md:col-end-12" /></address> }
          <nav className="col-span-6 md:col-start-12 md:col-end-13 uppercase block">
              <ul className="md:text-right">
                  {footerNav?.map(item => (
                      <li key={item._key || item.linkText}>
                          <NavLink {...item} />
                      </li>
                      ))}
              </ul>
          </nav>
      </div>
    </footer>
  )
}
