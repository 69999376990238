import { useStaticQuery, graphql } from 'gatsby';

export const useProjectQuery = () => {
  const { allSanityProjectPost } = useStaticQuery(graphql`
    query {
        allSanityProjectPost(sort: {fields: projectDate, order: DESC}) {
                    edges {
                    node {
                        _id
                        _key
                        slug {
                        _key
                        current
                        }
                        titleCard
                        seo {
                        metaDescription
                        metaImage {
                            _rawAsset(resolveReferences: {maxDepth: 10})
                            _type

                        }
                        metaTitle
                        }
                        title
                        projectDate
                                postImage {
                                    _rawAsset(resolveReferences: {maxDepth: 10})
                                        _key
                                        _type
                                        alt
                                        asset {
                                                gatsbyImageData(fit: FILLMAX, placeholder: DOMINANT_COLOR)
                                            }
                                        hotspot {
                                            _key
                                            _type
                                            height
                                            width
                                            x
                                            y
                                        }
                                        _rawCrop(resolveReferences: {maxDepth: 10})
                                        _rawHotspot
                                }
                                postExcerpt {
                                _key
                                style
                                children {
                                    _key
                                    _type
                                    marks
                                    text
                                }
                            }
                        }
                    }
                }
            }
  `);
  return allSanityProjectPost  || {};
};