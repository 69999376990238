import React, { useEffect, useState } from 'react'
import { StaticQuery, graphql, Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image'
import { motion } from "framer-motion";
import { useSiteConfig } from "../GraphQl/useSiteConfig"
import { useProjectQuery } from '../GraphQl/useProjectQuery'


const ProjectsAll = ({ handleCloseProject }) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const handleMouse = _key => {
        setActiveIndex(_key)
    }
    const { projectPageTitle } = useSiteConfig()
    const [ projects, setProjects ] = useState([]);

    const { edges } = useProjectQuery()

    useEffect(() => {
        console.log('project edges', edges)
    },[edges])

    return (
        <motion.div initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="fixed top-0 bottom-0 left-0 right-0 z-50 bg-secondary">
        <div className="section h-full" id="clientWrapper">
        <div className="col-span-6 h-full">
        <div id="all-clients-list">
        <div className="case-list--inner">
        <div className="case-list--back">
            <button className="close--btn mb-16" onClick={handleCloseProject}>
                <span className="close-text">Back</span>
            </button>
        </div>
        <div className="case-list--items">

        <h2 className="mb-5 text-p-heading font-heading">
            {projectPageTitle}
        </h2>
        <ul>
            {edges.map(client => 
                <li key={client.node._id} 
                    onMouseEnter={() => handleMouse(client.node._id)}
                    className="cursor-pointer flex mb-list">
                    <Link className="w-full" to={`/projects/${client.node.slug.current}`}>
                        <span className={`font-body text-p-main  transition duration-300 ease-in-out ${activeIndex === client.node._id ? "opacity-50" : "opacity-100" }`}>{client.node.title}</span>
                
                        {activeIndex === client.node._id &&
                            <motion.div key={client.node._id}
                            className="clientImage absolute top-0 right-0 left-1/2 hidden md:block"
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            transition={{ duration: 0.3 }}>
                                <GatsbyImage width={710} height={900} className="hidden md:block" objectFit="cover" objectPosition="center" alt={client?.node?.alt || 'Someday Studio Client Portfolio Image'} image={client?.node?.postImage?.asset?.gatsbyImageData} />
                            </motion.div>                                    
                        }
                    </Link>
                </li>
            )}
                            </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </motion.div>
    )
}

export default ProjectsAll
